import React from 'react'
import ContactCard from '../components/ContactCard/ContactCard'


function ContactPages(){

    return(
        <>
            <ContactCard/>

        </>

    )



}

export default ContactPages;